<template>
  <div class="bg-login-bg">
    <CContainer class="d-flex align-items-center min-vh-100 justify-content-center">
      <transition name="slide">
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard class="p-4">
                <CCardBody>
                  <CForm @submit.prevent="checkForm" method="POST">
                    <h1 class="text-black-50">Logowanie</h1>
                    <p class="text-muted">Wprowadź swoje dane aby się zalogować</p>
                    <CInput
                      v-model="email"
                      prependHtml="<i class='cui-user'></i>"
                      placeholder="Login"
                      autocomplete="username email"
                    >
                      <template #prepend-content>
                        <CIcon name="cil-user"/>
                      </template>
                    </CInput>
                    <CInput
                      v-model="password"
                      prependHtml="<i class='cui-lock-locked'></i>"
                      placeholder="Hasło"
                      type="password"
                      autocomplete="curent-password"
                    >
                      <template #prepend-content>
                        <CIcon name="cil-lock-locked"/>
                      </template>
                    </CInput>
                    <CRow>
                      <CCol col="12" class="text-left">
                        <CButton type="submit" color="primary" class="px-4">Zaloguj się</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </transition>
    </CContainer>
  </div>
</template>

<script>

import axios from "axios";
import Swal from 'sweetalert2';

//localStorage.setItem('roles', null);

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      message: '',
    }
  },
  methods: {
    goRegister() {
      this.$router.push({path: 'register'});
    },
    checkForm: function (e) {
      if (this.email && this.password) {
        this.login();
        return true;
      }
      Swal.fire(
        self.$i18n.translate('forms.transl64'),
        'Proszę uzupełnić wszystkie pola!',
        'error'
      )
      e.preventDefault();
    },

    login() {
      let self = this;
      axios.post(this.$apiAdress + '/api/login', {
        email: self.email,
        password: self.password,
      }).then(function (response) {
        self.email = '';
        self.password = '';
        localStorage.setItem("api_token", response.data.access_token);
        localStorage.setItem("auth", "true");
        self.$router.push({path: 'dashboard'});
      })
        .catch(function (error) {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            'Podane dane do logowania są błędne!',
            'error'
          )
          self.email = '';
          self.password = '';
        });

    }
  }
}

</script>
